"use strict";

class AnalyticsGraphComponentController {
    constructor($element, SSUtilService, SSHttpService, SSAppService, SSUserService) {
        const self = this;

        self.$element = $element;

        self.SSUtilService = SSUtilService;
        self.SSHttpService = SSHttpService;
        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;

        self.init();
    }

    init() {
        const self = this;

        self.data = null;

        if (self.loading) {
            return;
        }
        self.loading = true;

        let container = jQuery(self.$element.find('.card-block')[0]);

        loading(true, 0, container);
        self.getAnalytics(function (response) {
            loading(false, 0, container);

            self.data = [];

            if (response && response.analytics) {
                jQuery.each(response.analytics, function (i, e) {
                    let label = self.getGroupLabel(response, e);

                    let found = false;
                    jQuery.each(self.data, function (i2, e2) {
                        if (e2.label == label) {
                            e2.value += e.count;
                            found = true;
                            return false;
                        }
                    });

                    if (!found) {
                        self.data.push({
                            label: self.getGroupLabel(response, e),
                            value: e.count
                        });
                    }

                });
            }

            if (self.graph.type == 'table') {
                // pass
            } else {
                self.drawChart(self.graph.type, container, self.data);
            }
            self.loading = false;
        });
    }

    getGroupLabel(response, entity) {
        const self = this;

        let fields = [];
        let query = JSON.parse(self.graph.query);

        jQuery.each(query.group_by, function (i, e) {
            let value = null;

            if (e == 'property_id') {
                let obj = getFromArray(response.properties, 'id', entity[e]);
                value = obj ? obj.name : entity[e];
            } else if (e == 'unit_id') {
                let obj = getFromArray(response.units, 'id', entity[e]);
                value = obj ? self.SSUtilService.getFullUnitNumber(obj) : entity[e];
            } else if (['user_id', 'customer_user_id', 'broker_id', 'broker_user_id', 'sharer_user_id'].indexOf(e) > -1) {
                let obj = getFromArray(response.users, 'id', entity[e]);
                value = obj ? obj.display_name : entity[e];
            } else if (e == 'developer_id') {
                let obj = getFromArray(response.developers, 'id', entity[e]);
                value = obj ? obj.name : entity[e];
            } else {
                value = entity[e];
            }

            fields.push(value ? value : 'Other');
        });

        let label = '';
        jQuery.each(fields, function (i, e) {
            label += label ? ', ' + e : e;
        });

        if (self.graph.labels) {
            let labels = JSON.parse(self.graph.labels);
            if (label in labels) {
                label = labels[label];
            } else if ('*' in labels) {
                label = labels['*'];
            }
        }

        return label;
    }

    getAnalytics(callback) {
        const self = this;

        let url = 'sec/analytics/graphs/' + self.graph.id + '/data';

        self.SSHttpService.getAPIRequest(url).then(function (response) {
            if (response instanceof Error) {
                console.error(response);
                return;
            }

            callback(response.data);
        });
    }

    toggleDescription() {
        const self = this;

        let container = jQuery(self.$element.find('.card-block')[0]);

        if (self.descriptionVisible) {
            self.drawChart(self.graph.type, container, self.data);
        } else {
            container.html('<p>' + (self.graph.description ? self.graph.description : 'No info available.') + '</p>');
        }

        self.descriptionVisible = !self.descriptionVisible;
    }

    //-------------------------------------------
    drawChart(chart, container, data) {
        const self = this;

        if (chart == 'bar' || chart == 'column') {
            let labels = [];
            let values = [];
            let colors = [];

            jQuery.each(data, function(i, e) {
                labels.push(e.label);
                values.push([e.value]);
                colors.push('#2a6ebb');
            });

            if (chart == 'bar') {
                self.drawBarChart(container, labels, values, colors);
            } else if (chart == 'column') {
                self.drawColumnChart(container, labels, values, colors);
            }
        } else if (chart == 'pie') {
            let slices = [];
            let colors = [];

            jQuery.each(data, function(i, e) {
                slices.push({
                    name: e.label,
                    y: e.value,
                    sliced: i == 0 ? true : false,
                    selected: i == 0 ? true : false
                });
                colors.push(i == 0 ? '#009933' : '#' + ((10 - i) * 111));
            });

            self.drawPieChart(container, slices, colors);
        }
    }

    drawBarChart(container, categories, data, colors) {

        if (jQuery.isEmptyObject(data)) {
            container.html('No data.');
            return;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            chart: {
                type: 'bar',
                margin: [0, 0, 0, 80],
                events: {
                    click: function (event) {
                        if (clicked) {
                            //container.toggleClass('container-fullscreen');
                            //this.reflow();
                            clicked = false;
                        } else {
                            clicked = true;
                            setTimeout(function() {
                                clicked = false;
                            }, 500);
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b> - <b>' + this.y + '</b>';
                }
            },
            plotOptions: {
                series: {
                    colorByPoint: true,
                    pointWidth: 30,
                    pointPadding: 5,
                    groupPadding: 0,
                    dataLabels: {
                        enabled: true,
                        align: 'right',
                        color: '#FFFFFF',
                        x: -10
                    }
                }
            },
            colors: colors,
            xAxis: {
                categories: categories,
            },
            yAxis: {
                title: '',
                min: 0,
                allowDecimals: false,
                visible: false
            },
            series: [{
                data: data
            }],
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        });
    }

    drawColumnChart(container, categories, data, colors) {

        if (jQuery.isEmptyObject(data)) {
            container.html('No data.');
            return;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            chart: {
                type: 'column',
                events: {
                    click: function (event) {
                        if (clicked) {
                            //container.toggleClass('container-fullscreen');
                            //this.reflow();
                            clicked = false;
                        } else {
                            clicked = true;
                            setTimeout(function() {
                                clicked = false;
                            }, 500);
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b> - <b>' + this.y + '</b>';
                }
            },
            plotOptions: {
                series: {
                    colorByPoint: true,
                    pointWidth: 30,
                    pointPadding: 5,
                    groupPadding: 0
                }
            },
            colors: colors,
            xAxis: {
                categories: categories,
            },
            yAxis: {
                title: '',
                min: 0,
                allowDecimals: false,
            },
            series: [{
                data: data
            }],
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        });
    }

    drawPieChart(container, data, colors) {

        if (jQuery.isEmptyObject(data)) {
            container.html('No data.');
            return;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            chart: {
                type: 'pie',
                events: {
                    click: function (event) {
                        if (clicked) {
                            //container.parent().toggleClass('container-fullscreen');
                            //this.reflow();
                            clicked = false;
                        } else {
                            clicked = true;
                            setTimeout(function() {
                                clicked = false;
                            }, 500);
                        }
                    }
                }
            },
            colors: colors,
            title: {
                text: ' '
            },
            subtitle: {
                //text: 'Hover on pie slices to view data'
            },
            tooltip: {
                pointFormat: '<b>{point.percentage:.0f}%</b> ({point.y})',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        connectorWidth: 1
                    }
                }
            },
            series: [{
                data: data
            }],
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            credits: {
                enabled: false
            }
        });
    }
    //-------------------------------------------

}

AnalyticsGraphComponentController.$inject = ['$element', 'SSUtilService', 'SSHttpService', 'SSAppService', 'SSUserService'];
app.component('analyticsgraph', {
    controller: AnalyticsGraphComponentController,
    template: '' +
    '<div class="card grey-header">' +
    '    <div class="card-header text-center">' +
    '        <i class="fa fa-info-circle pull-left" style="cursor: pointer;" title="Info" ng-click="$ctrl.toggleDescription()"></i>' +
    '        {{ $ctrl.graph.name }}' +
    '        <i class="fa fa-refresh pull-right" style="cursor: pointer;" title="Refresh" ng-click="$ctrl.init()"></i>' +
    '    </div>' +
    '    <div class="card-block minor-padding" style="min-height: 380px;" ng-cloak>' +
    '        <table class="table table-striped table-hover table-responsive" ng-show="$ctrl.data != null" ng-cloak>' +
    '            <thead>' +
    '                <tr>' +
    '                    <th>Label</th>' +
    '                    <th>Value</th>' +
    '                </tr>' +
    '            </thead>' +
    '            <tbody>' +
    '                <tr ng-repeat="obj in $ctrl.data">' +
    '                    <td>{{obj.label}}</td>' +
    '                    <td>{{$ctrl.SSAppService.getFormattedValue(obj.value)}}</td>' +
    '                </tr>' +
    '            </tbody>' +
    '            <tfoot>' +
    '                <tr>' +
    '                    <th colspan="2">' +
    '                        <strong>{{$ctrl.data.length}}</strong> record(s) found.' +
    '                    </th>' +
    '                </tr>' +
    '            </tfoot>' +
    '        </table>' +
    '    </div>' +

        '<div class="card-footer text-center">' +
        '<i class="fa fa-expand pull-right mr-1" aria-hidden="true " style="cursor: pointer;" ng-click=""></i>' +
        '<i class="fa fa-window-maximize pull-right mr-1" aria-hidden="true" style="cursor: pointer;" ng-click=""></i>' +
        '<i class="fa fa-window-restore pull-right mr-1" aria-hidden="true" style="cursor: pointer;" ng-click=""></i>' +
        '</div>' +

    '</div>',
    bindings: {
        'graph': '<'
    }
});