"use strict";

class PaginationControlsComponentController {
    constructor(SSUtilService, SSAppService, SSUserService) {
        const self = this;

        self.SSUtilService = SSUtilService;
        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;

        self.clickPrevious = function () {
            self.onClickPrevious();
        };

        self.clickNext = function () {
            self.onClickNext();
        };

        self.init();
    }

    init() {
        const self = this;
    }
}

PaginationControlsComponentController.$inject = ['SSUtilService', 'SSAppService', 'SSUserService'];
app.component('paginationcontrols', {
    controller: PaginationControlsComponentController,
    template: '' +
    '<div class="pull-right">' +
    '    <loading ng-style="{float: \'left\', right: \'60px\', width: \'40px\', height: \'25px\'}"' +
    '             ng-show="$ctrl.showLoading"' +
    '             ng-cloak>' +
    '    </loading>' +
    '    <button class="btn-sm"' +
    '            ng-hide="$ctrl.current <= 1"' +
    '            ng-click="$ctrl.clickPrevious()">' +
    '        Previous' +
    '    </button>' +
    '    <span style="line-height: 26px;">' +
    '        Page {{ $ctrl.current }} of {{ $ctrl.total }}' +
    '    </span>' +
    '    <button class="btn-sm"' +
    '            ng-hide="$ctrl.current >= $ctrl.total"' +
    '            ng-click="$ctrl.clickNext()">' +
    '        Next' +
    '    </button>' +
    '</div>',
    bindings: {
        'showLoading': '<',
        'current': '<',
        'total': '<',
        'onClickNext': '&',
        'onClickPrevious': '&'
    }
});