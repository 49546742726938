  "use strict";

class CircleComponentController {
    constructor($scope, $element, $timeout) {
        const self = this;

        self.$scope = $scope;
        self.$element = $element;
        self.$timeout = $timeout;

        self.init();
    }

    init() {
        const self = this;

        self.setPercentage();

        self.$scope.$watch(
            function () {
                return self.value;
            }, function (newValue, oldValue) {
                if (newValue != oldValue) {
                    self.setPercentage();
                }
            }
        );
    }

    setPercentage() {
        const self = this;

        self.label = Math.round(self.value);

        self.$timeout(function () {
            self.$element.find('.circle').circleProgress({
                startAngle: -1.55,
                size: 80,
                value: self.value / 100,
                fill: {
                    color: '#071d49'
                }
            });

        });
    }
}

CircleComponentController.$inject = ['$scope', '$element', '$timeout'];
app.component('circle', {
    controller: CircleComponentController,
    template: '' +
    '<div class="circle">' +
    '    <strong>{{$ctrl.label}}%<br>SOLD</strong>' +
    '    <div class="inner-circle"></div>' +
    '</div>',
    bindings: {
        'value': '<',
    }
});