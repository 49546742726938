  "use strict";

class PaymentsGraphComponentController {
    constructor($element, SSUtilService, SSHttpService, SSAppService, SSUserService) {
        const self = this;

        self.$element = $element;
        self.SSUtilService = SSUtilService;
        self.SSHttpService = SSHttpService;
        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;

        self.init();
    }

    init() {
        const self = this;

        if (self.loading) {
            return;
        }
        self.loading = true;

        let container = jQuery(self.$element.find('.card-block')[0]);

        loading(true, 0, container);
       /* self.getAnalytics(function (response) {
            loading(false, 0, container);

            self.data = [];

            if (response && response.analytics) {
                jQuery.each(response.analytics, function (i, e) {
                    let label = self.getGroupLabel(response, e);

                    let found = false;
                    jQuery.each(self.data, function (i2, e2) {
                        if (e2.label == label) {
                            e2.value += e.count;
                            found = true;
                            return false;
                        }
                    });

                    if (!found) {
                        self.data.push({
                            label: self.getGroupLabel(response, e),
                            value: e.count
                        });
                    }

                });
            }

            self.drawChart(self.graph.type, container, self.data);
            self.loading = false;
        });*/

       // self.data = [];

        self.drawChart("pie", container, self.graph);
        self.loading = false;
    }

    getGroupLabel(response, entity) {
        const self = this;

        let fields = [];
        let query = JSON.parse(self.graph.query);

        jQuery.each(query.group_by, function (i, e) {
            let value = null;

            if (e == 'property_id') {
                let obj = getFromArray(response.properties, 'id', entity[e]);
                value = obj ? obj.name : entity[e];
            } else if (e == 'unit_id') {
                let obj = getFromArray(response.units, 'id', entity[e]);
                value = obj ? self.SSUtilService.getFullUnitNumber(obj) : entity[e];
            } else if (['user_id', 'customer_user_id', 'broker_id', 'broker_user_id', 'sharer_user_id'].indexOf(e) > -1) {
                let obj = getFromArray(response.users, 'id', entity[e]);
                value = obj ? obj.display_name : entity[e];
            } else if (e == 'developer_id') {
                let obj = getFromArray(response.developers, 'id', entity[e]);
                value = obj ? obj.name : entity[e];
            } else {
                value = entity[e];
            }

            fields.push(value ? value : 'Other');
        });

        let label = '';
        jQuery.each(fields, function (i, e) {
            label += label ? ', ' + e : e;
        });

        if (self.graph.labels) {
            let labels = JSON.parse(self.graph.labels);
            if (label in labels) {
                label = labels[label];
            } else if ('*' in labels) {
                label = labels['*'];
            }
        }

        return label;
    }

    getAnalytics(callback) {
        const self = this;

        let url = 'sec/analytics/graphs/' + self.graph.id + '/data';

        self.SSHttpService.getAPIRequest(url).then(function (response) {
            if (response instanceof Error) {
                console.error(response);
                return;
            }

            callback(response.data);
        });
    }

    toggleDescription() {
        const self = this;

        let container = jQuery(self.$element.find('.card-block')[0]);

        if (self.descriptionVisible) {
            self.drawChart(self.graph.type, container, self.data);
        } else {
            container.html('<p>' + (self.graph.description ? self.graph.description : 'No info available.') + '</p>');
        }

        self.descriptionVisible = !self.descriptionVisible;
    }

    //-------------------------------------------
    drawChart(chart, container, data) {
        const self = this;

        if (chart == 'bar' || chart == 'column') {
            let labels = [];
            let values = [];
            let colors = [];

            jQuery.each(data, function(i, e) {
                labels.push(e.label);
                values.push([e.value]);
                colors.push(i == 0 ? '#009933' : '#' + ((10 - i) * 111));
            });

            if (chart == 'bar') {
                self.drawBarChart(container, labels, values, colors);
            } else if (chart == 'column') {
                self.drawColumnChart(container, labels, values, colors);
            }
        } else if (chart == 'pie') {
            let slices = [];
            let colors = [];


                slices=data;
                   // sliced: i == 0 ? true : false,
                   // selected: i == 0 ? true : false
                //});



            self.drawPieChart(container, slices, colors);
        }
    }

    drawChart_property(chart, data) {
        const self = this;
        let container = jQuery(self.$element.find('.card-bk')[0]);

        if (chart == 'bar' || chart == 'column') {
            let labels = [];
            let values = [];
            let colors = [];

            jQuery.each(data, function(i, e) {
                labels.push(e.label);
                values.push([e.value]);
                colors.push(i == 0 ? '#009933' : '#' + ((10 - i) * 111));
            });

            if (chart == 'bar') {
                self.drawBarChart(container, labels, values, colors);
            } else if (chart == 'column') {
                self.drawColumnChart(container, labels, values, colors);
            }
        } else if (chart == 'pie') {
            let slices = [];
            let colors = [];

            jQuery.each(data, function(i, e) {
                slices.push({
                    name: e.label,
                    y: e.value,
                    sliced: i == 0 ? true : false,
                    selected: i == 0 ? true : false
                });
                colors.push(i == 0 ? '#009933' : '#' + ((10 - i) * 111));
            });

            self.drawPieChart(container, slices, colors);
        }
    }

    drawBarChart(container, categories, data, colors) {

        if (jQuery.isEmptyObject(data)) {
            container.html('No data..');
            return;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            chart: {
                type: 'bar',
                margin: [0, 0, 0, 80],
                events: {
                    click: function (event) {
                        if (clicked) {
                            //container.toggleClass('container-fullscreen');
                            //this.reflow();
                            clicked = false;
                        } else {
                            clicked = true;
                            setTimeout(function() {
                                clicked = false;
                            }, 500);
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b> - <b>' + this.y + '</b>';
                }
            },
            plotOptions: {
                series: {
                    colorByPoint: true,
                    pointWidth: 30,
                    pointPadding: 5,
                    groupPadding: 0,
                    dataLabels: {
                        enabled: true,
                        align: 'right',
                        color: '#FFFFFF',
                        x: -10
                    }
                }
            },
            colors: colors,
            xAxis: {
                categories: categories,
            },
            yAxis: {
                title: '',
                min: 0,
                allowDecimals: false,
                visible: false
            },
            series: [{
                data: data
            }],
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        });
    }

    drawColumnChart(container, categories, data, colors) {

        if (jQuery.isEmptyObject(data)) {
            container.html('No data..');
            return;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            chart: {
                type: 'column',
                events: {
                    click: function (event) {
                        if (clicked) {
                            //container.toggleClass('container-fullscreen');
                            //this.reflow();
                            clicked = false;
                        } else {
                            clicked = true;
                            setTimeout(function() {
                                clicked = false;
                            }, 500);
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b> - <b>' + this.y + '</b>';
                }
            },
            plotOptions: {
                series: {
                    colorByPoint: true,
                    pointWidth: 30,
                    pointPadding: 5,
                    groupPadding: 0,
                    dataLabels: {
                        enabled: true,
                        align: 'top',
                        color: '#FFFFFF',
                        y: 25
                    }
                }
            },
            colors: colors,
            xAxis: {
                categories: categories,
            },
            yAxis: {
                title: '',
                min: 0,
                allowDecimals: false,
            },
            series: [{
                data: data
            }],
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        });
    }

    drawPieChart(container, data, colors) {

        if (!data) {
            data=0;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            title: {
                text: 'Payment Progress',
                align: 'left',
                margin: 0,
            },
            chart: {
                renderTo: 'paymentprogress',
                type: 'bar',
                height: 70,
                width: 250

            },
            credits: false,
            tooltip: false,
            legend: false,
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            xAxis: {
                visible: false,
            },
            yAxis: {
                visible: false,
                min: 0,
                max: 100,
            },
            series: [{
                data: [100],
                grouping: false,
                animation: false,
                enableMouseTracking: false,
                showInLegend: false,
                color: 'lightskyblue',
                pointWidth: 25,
                borderWidth: 0,
                borderRadiusTopLeft: '4px',
                borderRadiusTopRight: '4px',
                borderRadiusBottomLeft: '4px',
                borderRadiusBottomRight: '4px',
                dataLabels: {
                    className: 'highlight',
                    format: '',
                    enabled: true,
                    align: 'right',
                    style: {
                        color: 'white',
                        textOutline: false,
                    }
                }
            }, {
                enableMouseTracking: false,
                data: [data],
                borderRadiusBottomLeft: '4px',
                borderRadiusBottomRight: '4px',
                color: 'navy',
                borderWidth: 0,
                pointWidth: 25,
                animation: {
                    duration: 250,
                },
                dataLabels: {
                    enabled: true,
                    inside: true,
                    align: 'left',
                    format: '{point.y}%',
                    style: {
                        color: 'white',
                        textOutline: false,
                    }
                }
            }]
        });
    }
    //-------------------------------------------

}


PaymentsGraphComponentController.$inject = ['$element', 'SSUtilService', 'SSHttpService', 'SSAppService', 'SSUserService'];
  app.component('paymentprogress', {
      controller: PaymentsGraphComponentController,
      template: '' +
      '    <div class="card-block card-bk p-0"></div>' +
      '</div>',
      bindings: {
          'graph': '<'
      }
  });

  