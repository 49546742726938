"use strict";

class UnitsTableComponentController {
  constructor($log, $localStorage, SSUtilService, SSAppService, SSUserService) {
    const self = this;
    self.$log = $log;
    self.$localStorage = $localStorage;
    self.SSUtilService = SSUtilService;
    self.SSAppService = SSAppService;
    self.SSUserService = SSUserService;

    self._onClickView = function (unit) {
      if (self.onClickView)
        self.onClickView({unit: unit});
    };

    self.init();
  }

  init() {
    const self = this;
  }
}

UnitsTableComponentController.$inject = ['$log', '$localStorage', 'SSUtilService', 'SSAppService', 'SSUserService'];
app.component('unitstable', {
  controller: UnitsTableComponentController,
  template: '' +
  '<div class="residence-box col-xs-12">' +
  '<h3 class="bold-font pull-left" ng-show="$ctrl.heading" ng-cloak>{{ $ctrl.heading }}</h3>' +
  '<table class="table table-hover dt-responsive nowrap" cellspacing="0" width="100%" border="0">' +
  '    <thead>' +
  '    <tr>' +
  '        <th>#UNIT NO</th>' +
  '        <th>TYPE</th>' +
  '        <th>BATH</th>' +
  '        <th>AREA (SQFT)</th>' +
  '        <th>$PSF</th>' +
  '        <th>PRICE</th>' +
  '        <th>VIEW</th>' +
  '    </tr>' +
  '    </thead>' +
  '    <tbody ng-repeat="unit in $ctrl.units" ng-cloak>' +
  '    <tr class="unitstable-row" >' +
  '        <td>#{{ $ctrl.SSUtilService.getUnitNumberStr(unit.floor_no) }}-{{ $ctrl.SSUtilService.getUnitNumberStr(unit.unit_no) }}</td>' +
  '        <td>{{ unit.name }}</td>' +
  '        <td>{{ unit.baths }}</td>' +
  '        <td>{{ unit.size_ft2 ? $ctrl.SSAppService.getFormattedValue(unit.size_ft2) : "-" }}</td>' +
  '        <td>{{ unit.psf ? $ctrl.SSAppService.getFormattedAmount(unit.psf) : "-" }}</td>' +
  '        <td>{{ $ctrl.SSAppService.getFormattedAmountOrRange(unit) }}</td>' +
  '        <td ng-click="$ctrl._onClickView(unit)"><i class="fa fa-angle-double-right"></i></td>' +
  '    </tr>' +
  '    </tbody>' +
  '</table>' +
  '</div>',
  bindings: {
    'units': '<',
    'heading': '@',
    'onClickView': '&'
  }
});