/**
 * Created by Khawaja Hammad on 22/05/2017.
 */
"use strict";

class PropertyListItemHorizontalComponentController {
    constructor($log, $localStorage, SSUtilService, SSAppService, SSUserService) {
        const self = this;
        self.$log = $log;
        self.$localStorage = $localStorage;
        self.SSUtilService = SSUtilService;
        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;

        self.click = function (property) {
            self.onClick({property: property});
        };

        self.init();
    }

    init() {
        const self = this;
    }
}

PropertyListItemHorizontalComponentController.$inject = ['$log', '$localStorage', 'SSUtilService', 'SSAppService', 'SSUserService'];
app.component('propertylistitemhorizontal', {
    controller: PropertyListItemHorizontalComponentController,
    template: '' +
    '<div class="col-xs-12">' +
    '<div class="all-projects col-xs-12">' +
    '    <a ng-click="$ctrl.click($ctrl.property)">' +
    '        <figure class="pull-left col-md-2 col-xs-4">' +
    '            <img class="" ng-src="{{$ctrl.SSAppService.getPropertyHeroThumbUrl($ctrl.property)}}" alt="{{$ctrl.property.name}}">' +
    '        </figure>' +
    '    </a>' +
    '    <aside class="regular-font project-details col-md-8 col-xs-6 pull-left">' +
    '        <h3><img ng-src="/images/building.png" alt=""> {{$ctrl.property.name}}</h3>' +
    '        <p>{{$ctrl.property.location}}, {{$ctrl.SSAppService.getCountryName($ctrl.property.country)}}, <span' +
    '                    class="bold-font">{{$ctrl.property.region}}</span><br>' +
    '            Units Balance : {{$ctrl.SSAppService.getRemainingUnits($ctrl.property)}} / {{$ctrl.SSAppService.getTotalUnits($ctrl.property)}}<br>' +
    '            AVG PSF$: {{$ctrl.SSAppService.getFormattedAmount($ctrl.property.psf)}}<br>' +
    '            Tenure: {{$ctrl.property.tenure}}</p>' +
    '    </aside>' +
    '    <div class="apartment-address pull-right">' +
    '        <loading ng-style="{position: \'absolute\', right: \'60px\', top: \'15px\', width: \'25px\', height: \'25px\'}"' +
    '                 ng-show="$ctrl.showLoading" ng-cloak></loading>' +
    '        <ul>' +
    '            <li class="heart" ng-class="$ctrl.SSUserService.isPropertyFavourite($ctrl.property.id) ? \'active\' : \'\'"' +
    '                ng-click="$ctrl.SSUserService.updatePropertyFavorite($ctrl.property.id)">' +
    '            </li>' +
    '            <li><a ng-click="$ctrl.SSAppService.compareProperty($ctrl.property)">' +
    '                    <img ng-src="/images/add.png" alt="">' +
    '                </a>' +
    '            </li>' +
    '            <li><a ng-click="$ctrl.SSAppService.getRandomCode($ctrl.property)">' +
    '                    <img ng-src="/images/share.png" alt="">' +
    '                </a>' +
    '            </li>' +
    '        </ul>' +
    '    </div>' +
    '</div>' +
    '</div>',
    bindings: {
        'property': '<',
        'onClick': '&',
        'showLoading': '<'
    }
});
//app.component('propertylistitemhorizontal', {
//    controller: PropertyListItemHorizontalComponentController,
//    template: '' +
//    '<div class="col-xs-12">'+
//    <table class="table table-striped">
//    <tr style="cursor: pointer" ng-repeat="favouriteProperty in crtl.getFavouriteProperty" ng-click="crtl.customerPropertyDetail(favouriteProperty.named_id)">
//    <td width="15%" >
//    <img class="img-responsive"
//
//ng-src="{{Config::get('aws.base_s3_url')}}/@{{favouriteProperty.property_assets[0].file_uri}}"
//alt="@{{ favouriteProperty.name }}">
//    </td>
//    <td>
//    <div class="caption-title-text" style="margin-bottom: 5px;">
//    <img src="{{URL::asset('/images/ic-dark-property.png')}}"/> <b>@{{ favouriteProperty.name }}</b>
//</div>

//<div class="caption-address-text"> @{{ favouriteProperty.location }}</div>
//<div class=""> District: @{{ favouriteProperty.region }}</div>
//<div class=""> AVG PSF: $ @{{ favouriteProperty.psf }}</div>
//<div class=""> Tenure: $ @{{ favouriteProperty.tenure }}</div>
//</td>
//<td width="5%">
//    <div class="pull-right">
//    <div class="col-xs-12 item" style="margin: 2px">
//    <a href="javascript:;">
//    <span><img src="{{URL::asset('/images/ic-blue-heart.png')}}" height="20"/></span>
//    </a>
//    </div>
//    <div class="col-xs-12 item" style="margin: 2px">
//    <a href="javascript:;">
//    <span><img src="{{URL::asset('/images/ic-blue-share.png')}}" height="20"/></span>
//    </a>
//    </div>
//    <div class="col-xs-12 item" style="margin: 2px">
//    <a href="javascript:;">
//    <span><img src="{{URL::asset('/images/ic-blue-compare.png')}}" height="20"/></span>
//    </a>
//    </div>
//    </div>
//    </td>
//    </tr>
//    </table>
//    <div ng-if="crtl.getFavouriteProperty.length == null || crtl.getFavouriteProperty.length < 0"><h5>No Projects available.</h5></div>
//</div>,
//    bindings: {
//        'property': '<',
//        'onClick': '&',
//        'showLoading': '<'
//    }
//});