"use strict";

class CarousalComponentController {
  constructor($log, $localStorage, SSUtilService, SSAppService) {
    const self = this;
    self.$log = $log;
    self.$localStorage = $localStorage;
    self.SSUtilService = SSUtilService;
    self.SSAppService = SSAppService;

    self.init();
  }

  init() {
    const self = this;
  }

  openUrl(url) {
    window.location.href = url;
  }
}

CarousalComponentController.$inject = ['$log', '$localStorage', 'SSUtilService', 'SSAppService'];
app.component('carousal', {
  controller: CarousalComponentController,
  template: '' +
'<div id="{{ $ctrl.carouselid }}" class="carousel slide" data-ride="carousel">' +
  '<ol class="carousel-indicators">' +
    '<li data-target="#{{ $ctrl.carouselid }}" data-slide-to="{{ $index }}" class="active" ng-repeat-start="asset in $ctrl.assets" ng-if="$first"></li>' +
    '<li data-target="#{{ $ctrl.carouselid }}" data-slide-to="{{ $index }}" class="" ng-repeat-end ng-if="!$first"></li>' +
  '</ol>' +
  '<div class="carousel-inner" role="listbox">' +
    '<div class="carousel-item active" ng-repeat-start="asset in $ctrl.assets" ng-if="$first">' +
      '<img class="mx-auto d-block obj-fit-contain" ng-src="{{ $ctrl.SSAppService.getAssetUrl(asset.file_uri) }}" alt="{{ asset.name }}">' +
      '<div class="carousel-caption d-md-block">' +
        '<p>{{ asset.name }}</p>' +
      '</div>' +
    '</div>' +
    '<div class="carousel-item" ng-repeat-end ng-if="!$first">' +
      '<img class="mx-auto d-block obj-fit-contain" ng-src="{{ $ctrl.SSAppService.getAssetUrl(asset.file_uri) }}" alt="{{ asset.name }}">' +
      '<div class="carousel-caption d-md-block">' +
        '<p>{{ asset.name }}</p>' +
      '</div>' +
    '</div>' +
  '</div>' +
'</div>',
  bindings: {
    'assets': '<',
    'carouselid': '@',
    'imagehover':'@',
  }
});

//app.component('carousal', {
//  controller: CarousalComponentController,
//  template: '' +
//  '<div class="carousel slide" ng-if="$ctrl.assets && $ctrl.assets.length == 1">' +
//  '    <div class="carousel-inner">' +
//  '        <div class="item active ">' +
//  '            <img ng-src="{{ $ctrl.SSAppService.getAssetUrl($ctrl.assets[0].file_uri) }}" alt="{{ $ctrl.assets[0].name }}" fullscreen class="{{ $ctrl.imagehover }}" >' +
//  '        </div>' +
//  '    </div>' +
//  '</div>' +
//  '<div id="{{ $ctrl.carouselid }}" class="carousel slide" data-ride="carousel" ng-if="$ctrl.assets && $ctrl.assets.length > 1">' +
//  '    <ol class="carousel-indicators">' +
//  '        <li data-target="#{{ $ctrl.carouselid }}" data-slide-to="{{ $index }}" class="active" ng-repeat-start="asset in $ctrl.assets" ng-if="$first"></li>' +
//  '        <li data-target="#{{ $ctrl.carouselid }}" data-slide-to="{{ $index }}" class="" ng-repeat-end ng-if="!$first"></li>' +
//  '    </ol>' +
//  '    <div class="carousel-inner" role="listbox">' +
//  '        <div class="item active" ng-repeat-start="asset in $ctrl.assets" ng-if="$first">' +
//  '            <a ng-if="asset.href" ng-click="$ctrl.openUrl(asset.href)">' +
//  '                <img ng-src="{{ $ctrl.SSAppService.getAssetUrl(asset.file_uri) }}" alt="{{ asset.name }}" fullscreen class="{{ $ctrl.imagehover }}">' +
//  '            </a>' +
//  '            <img ng-if="!asset.href" ng-src="{{ $ctrl.SSAppService.getAssetUrl(asset.file_uri) }}" alt="{{ asset.name }}" fullscreen class="{{ $ctrl.imagehover }}">' +
//  '        </div>' +
//  '        <div class="item" ng-repeat-end ng-if="!$first">' +
//  '            <a ng-if="asset.href" ng-click="$ctrl.openUrl(asset.href)">' +
//  '                <img ng-src="{{ $ctrl.SSAppService.getAssetUrl(asset.file_uri) }}" alt="{{ asset.name }}" fullscreen class="{{ $ctrl.imagehover }}">' +
//  '            </a>' +
//  '            <img ng-if="!asset.href" ng-src="{{ $ctrl.SSAppService.getAssetUrl(asset.file_uri) }}" alt="{{ asset.name }}" fullscreen class="{{ $ctrl.imagehover }}">' +
//  '        </div>' +
//  '    </div>' +
//  '    <a class="left carousel-control" ng-href="#{{ $ctrl.carouselid }}" role="button" data-slide="prev">' +
//  '        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>' +
//  '        <span class="sr-only">Previous</span>' +
//  '    </a>' +
//  '    <a class="right carousel-control" ng-href="#{{ $ctrl.carouselid }}" role="button" data-slide="next">' +
//  '        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>' +
//  '        <span class="sr-only">Next</span>' +
//  '    </a>' +
//  '</div>',
//  bindings: {
//    'assets': '<',
//    'carouselid': '@',
//    'imagehover':'@',
//  }
//});