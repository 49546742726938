"use strict";

class SocialMediaModalComponentController {
    constructor(SSUserService, SSAppService) {
        const self = this;

        self.SSUserService = SSUserService;
        self.SSAppService = SSAppService;

        self.init();
    }

    init() {
        const self = this;
    }
}

SocialMediaModalComponentController.$inject = ['SSUserService', 'SSAppService'];
app.component('socialmediamodal', {
    controller: SocialMediaModalComponentController,
    template: '' +
    '<div id="socialModal" class="modal fade login-modal login-pop" tabindex="-1" role="dialog">' +
    '  <div class="modal-dialog" role="document">' +
    '    <div class="modal-content">' +
    '      <div class="modal-header no-border">' +
    '        <h2 class="col-md-12 text-center">Share</h2>' +
    '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
    '          <span aria-hidden="true"><i class="icon icon-exit"></i></span>' +
    '        </button>' +
    '      </div>' +
    '      <div class="modal-body">' +
    '      <div class="clip-msg alert-success">' +
    '      </div>' +
    '        <div class="share-buttons">' +
    '          <a class="btn btn-link" style="color: #d4af37;" ng-click="$ctrl.SSAppService.socialMediaShare($event, \'email\')"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>' +
    '          <a class="btn btn-link toolTipTxt" socialShare="Shared on Facebook Successfully!" style="color: #3b5998;" ng-click="$ctrl.SSAppService.socialMediaShare($event, \'facebook\')"><i class="fa fa-facebook" aria-hidden="true"></i></a>' +
    '          <a class="btn btn-link toolTipTxt" target="_blank" socialShare="Shared on Twitter Successfully!" style="color: #1da1f2;" ng-click="$ctrl.SSAppService.socialMediaShare($event, \'twitter\')"><i class="fa fa-twitter" aria-hidden="true"></i></a>' +
    '          <a class="btn btn-link btn-social-share-copy toolTipTxt" socialShare="Copied Successfully!" data-clipboard-target="#socialShareLinkCopy" style="color: #000000;" ng-click="$ctrl.SSAppService.socialMediaShare($event, \'clipboard\')"><i class="fa fa-files-o" aria-hidden="true"></i></i></a>' +
    '        </div>' +
    '        <p class="text-center" ng-cloak>' +
    '          Sharing means you\'re agreeable with Showsuite\'s <a href="/more/terms-of-use">EULA</a> and <a href="/more/privacy-policy">Privacy Policy</a>' +
    '        </p>' +
    '      </div>' +
    '    </div>' +
    '  </div>' +
    '</div>',
    bindings: {}
});