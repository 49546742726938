"use strict";

class ModalComponentController {
  constructor($scope, $rootScope) {
    const self = this;
    self.$scope = $scope;
    self.$rootScope = $rootScope;

    self.init();
  }

  init() {
    const self = this;

    self.$scope.$watch(()=>self.show, function (newValue, oldValue) {
      if (newValue !== oldValue) {
        self.updateBodyClass();
        //self.toggleModal();
      }
    });
  }

  updateBodyClass() {
    const self = this;

    if (self.show) {
      self.$rootScope.bodyClass = 'modal-open';
    } else {
      self.$rootScope.bodyClass = '';
    }
  }

  toggleModal() {
    const self = this;

    console.log('toggle');
    if (self.show) {
      console.log('show: #'+self.modId);
      $('#'+self.modId).modal('show');
    } else {
      $('#'+self.modId).modal('hide');
    }
  }

}

//app.component('modal', {
//  controller: ModalComponentController,
//  transclude: true,
//  template: '' +
//  '<div ng-show="$ctrl.show">' +
//  '    <div id="{{ $ctrl.modId }}" class="modal fade login-modal" tabindex="-1" role="dialog" aria-labelledby="$ctrl.modLabel">' +
//  '        <div class="modal-dialog" role="document">' +
//  '        <div class="modal-content">' +
//  '            <div class="modal-header">' +
//  '                <button type="button" class="close" ng-click="$ctrl.show = false">&times;</button>' +
//  '            </div>' +
//  '            <div class="modal-body" ng-transclude>' +
//  '            </div>' +
//  '        </div>' +
//  '    </div>' +
//  '</div>',
//  bindings: {
//    'show': '=',
//    'modId': '@',
//    'modLabel': '@'
//  }
//});

ModalComponentController.$inject = ['$scope', '$rootScope'];
app.component('modal', {
  controller: ModalComponentController,
  transclude: true,
  template: '' +
  '<div class="modal-container ng-fade" ng-show="$ctrl.show">' +
  '    <div class="modal login-pop" role="dialog">' +
  '        <div class="modal-backdrop fade in" ng-click="$ctrl.show = false"></div>' +
  '        <div class="modal-content">' +
  '            <div class="modal-header">' +
  '                <button type="button" class="close" ng-click="$ctrl.show = false">&times;</button>' +
  '            </div>' +
  '            <div class="modal-body" ng-transclude>' +
  '            </div>' +
  '        </div>' +
  '    </div>' +
  '</div>',
  bindings: {
    'show': '='
  }
});