  "use strict";

class PeopertyBoxComponentController {
    constructor(SSUtilService, SSAppService, SSUserService) {
        const self = this;

        self.SSUtilService = SSUtilService;
        self.SSAppService = SSAppService;
        self.SSUserService = SSUserService;
    }

    _showUnitStats() {
        const self = this;

        if (self.unitsAvailable || self.floorNo || self.unitNo) {
            return false;
        }

        return self.SSAppService.isPropertySettingEnabled(self.property, 'unit_statistics_circle_visibility', 1);
    }

    _showTotalUnits() {
        const self = this;

        if (self.unitsAvailable || self.floorNo || self.unitNo) {
            return false;
        }

        return !self.SSAppService.isPropertySettingEnabled(self.property, 'unit_statistics_circle_visibility', 1);
    }

    _onClick() {
        const self = this;

        self.onClick({
            property: self.property
        });
    }

    _onRemoveFromHistory() {
        const self = this;

        self.onRemoveFromHistory({
            table_id: 'properties',
            column_id: self.property.id
        });
    }

}

PeopertyBoxComponentController.$inject = ['SSUtilService', 'SSAppService', 'SSUserService'];
app.component('propertybox', {
    controller: PeopertyBoxComponentController,
    template: '' +
    '<div class="apartment text-center">' +
    '    <a href="javascript:;">' +
    '        <figure class="apartment-figure">' +
    '            <a href="" class="btn yellow" ng-show="$ctrl.SSAppService.isPropertyProductManagementSuite($ctrl.property.product_type)" ng-click="$ctrl._onClick($ctrl.property)">BOOK NOW</a>' +
    '            <a href="" class="btn red" ng-show="$ctrl.property.latest">NEW!</a>' +
    '            <a href="" class="btn close" ng-click="$ctrl._onRemoveFromHistory()" ng-show="$ctrl.SSAppService.historyPage" title="Remove from History"><i class="icon icon-reject"></i></a>' +
    '            <img class="obj-fit-cover"' +
    '                 style="cursor: pointer;"' +
    '                 ng-click="$ctrl._onClick($ctrl.property)"' +
    '                 ng-src="{{$ctrl.SSAppService.getPropertyHeroThumbUrl($ctrl.property)}}"' +
    '                 alt="{{$ctrl.property.name}}">' +
    '            <div class="apartment-address text-centre">' +
    '               <div class="apartment-text">'+
    '                 <div class="text-wrapper">'+
    '                   <p ng-show="$ctrl.property.units_last_updated_at" class="last-updated">Last updated: {{ $ctrl.SSUtilService.formatDateDMY($ctrl.property.units_last_updated_at) }}</p>'+
    '                   <h3 ng-click="$ctrl._onClick($ctrl.property)" style="cursor: pointer;">{{$ctrl.property.name}}</h3>'+
    '                   <p>{{$ctrl.property.location}}, {{$ctrl.property.region}}</p>'+
    '                 </div>'+
    '               </div>'+
    '                <div class="apartment-share">' +
    '                    <loading ng-style="{position: \'absolute\', right: \'60px\', top: \'5px\', width: \'25px\', height: \'25px\'}"' +
    '                       ng-show="$ctrl.showLoading" ng-cloak></loading>' +
    '                    <ul class="list-unstyled" ng-hide="$ctrl.floorNo || $ctrl.unitNo">' +
    '                        <li ng-class="$ctrl.SSUserService.isPropertyFavourite($ctrl.property.id) ? \'heart\' : \'\'">' +
    '                            <a ng-click="$ctrl.SSUserService.updatePropertyFavorite($ctrl.property.id)">' +
    '                                <i class="icon icon-favorite" aria-hidden="true"></i>' +
    '                            </a>' +
    '                        </li>' +
    '                        <li class="plus"><a href="javascript:;" ng-click="$ctrl.SSAppService.compareProperty($ctrl.property)"><i class="icon icon-add-to-compare" aria-hidden="true"></i></a></li>' +
    '                        <li class="share"><a href="javascript:;" ng-click="$ctrl.SSAppService.getRandomCode($ctrl.property)"><i class="icon icon-share" aria-hidden="true"></i></a>' +
    '                        </li>' +
    '                    </ul>' +
    '                </div>' +
    '            </div>' +
    '        </figure>' +
    '        <div class="col-xs-12 sold-box">' +
    '            <ul class="list-unstyled clearfix">' +
    '                <li class="bold-font">' +
    '                    <figure class="figure-icon">' +
    '                        <img class="obj-fit-cover" ng-src="/images/apartment-icon1.png" alt="">' +
    '                    </figure>' +
    '                    <span>{{$ctrl.SSAppService.getFormattedAmount($ctrl.property.psf)}} psf</span>' +
    '                </li>' +
    '                <li class="sold justified" style="cursor: pointer;" ng-show="$ctrl.unitsAvailable" ng-click="$ctrl._onClick($ctrl.property)">' +
    '                    <p>Available <span>{{ $ctrl.unitsAvailable }} {{ $ctrl.unitsAvailable > 1 ? "units" : "unit" }} </span></p>' +
    '                </li>' +
    '                <li class="sold" ng-show="$ctrl._showUnitStats()">' +
    '                    <div class="availability">' +
    '                        <span></span>{{$ctrl.SSAppService.getRemainingUnits($ctrl.property)}}<br>available</span>' +
    '                    </div>' +
    '                    <circle value="$ctrl.SSAppService.getSoldPercentage($ctrl.property)"/>' +
    '                    <div class="total-sold"><span>{{$ctrl.SSAppService.getSoldUnits($ctrl.property)}}<br>sold</span></div>' +
    '                </li>' +
    '                <li class="sold justified" ng-show="$ctrl._showTotalUnits()">' +
    '                    <p>Total <span>{{$ctrl.SSAppService.getTotalUnits($ctrl.property)}} unit(s)</span></p>' +
    '                </li>' +
    '                <li class="sold justified" ng-show="$ctrl.floorNo || $ctrl.unitNo">' +
    '                    <p>' +
    '                        {{ $ctrl.SSUtilService.getFullUnitNumber2($ctrl.floorNo, $ctrl.unitNo) }}' +
    '                        <span>Agent: <a href="/agents">{{ $ctrl.property.broker.user.display_name }}</a></span>' +
    '                    </p>' +
    '                </li>' +
    '                <li class="bold-font">' +
    '                    <figure class="figure-icon">' +
    '                        <img ng-src="/images/apartment-icon2.png" alt="">' +
    '                    </figure>' +
    '                    <span>{{$ctrl.property.tenure}}</span>' +
    '                </li>' +
    '            </ul>' +
    '        </div>' +
    '    </a>' +
    '</div>',
    bindings: {
        'property': '<',
        'onClick': '&',
        'onRemoveFromHistory': '&',
        'unitsAvailable': '<',
        'unitNo': '<',
        'floorNo': '<',
        'showLoading': '<',
    }
});